import React from "react";
import { useEffect } from "react";

import Ustav from "../assets/ustav-ingis.pdf";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="content" id="#top">
        <div className="documents">
          <div className="documents--title">
            <div class="doc_info">
              <div class="doc_name">
                <a href={Ustav}>
                  <h3>
                    Устав благотворительного фонда поддержки исторической памяти
                    ингушского народа "ИНГИС"
                  </h3>
                </a>
              </div>

              <div class="doc_open">
                <a href={Ustav}>
                  <span>открыть</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="structure--fond">
          <div className="structure--fond">
            <div className="structure--fond--rows">
              <table className="structure__table--one">
                <tr>
                  <td colspan="3" className="structure--fond--title">
                    Состав благотворительного фонда
                  </td>
                </tr>
                <tr>
                  <td colspan="3">Совет Фонда</td>
                </tr>
                <tr>
                  <td className="pad">№п.п</td>
                  <td>Ф.И.О</td>
                  <td>Должности</td>
                </tr>
                <tr>
                  <td className="pad">1.</td>
                  <td>Картоев Магомед Муссаевич</td>
                  <td>
                    Руководитель Государственной архивной службы Республики
                    Ингушетия
                  </td>
                </tr>
                <tr>
                  <td className="pad">2.</td>
                  <td>Сагов Магомед Закриевич</td>
                  <td>
                    Руководитель ГКУ "Ингушской государственный музей
                    краеведения им. Т. Мальсагова"
                  </td>
                </tr>

                <tr>
                  <td className="pad">3.</td>
                  <td>Мартазанов Мусса Яхьяевич</td>
                  <td>Председатель Союза художников Республики Ингушетия</td>
                </tr>

                <tr>
                  <td className="pad">4.</td>
                  <td>Харсиев Борис Магомед-Гиреевич</td>
                  <td>
                    Директор ГБУ "ингушский научно-исследовательский институт
                    гуманитарных наук им. ч.э. Ахриева"
                  </td>
                </tr>

                <tr>
                  <td className="pad">5.</td>
                  <td>Газиков Берснако Джабраилович</td>
                  <td>Ингушский исследователь-краевед</td>
                </tr>

                <tr>
                  <td className="pad">6.</td>
                  <td>Цороев Ахмед Хаджибикарович</td>
                  <td>Депутат Народного Собрания Республики Ингушетия</td>
                </tr>
              </table>
              <table>
                <tr>
                  <td colspan="3" className="structure--fond--title">
                    Попечительский Совет
                  </td>
                </tr>
                <tr>
                  <td className="pad">№п.п</td>
                  <td>Ф.И.О</td>
                  <td>Должности</td>
                </tr>
                <tr>
                  <td className="pad">1.</td>
                  <td>Гойгова Хадижат Магомедовна</td>
                  <td>
                    Заместитель главы администрации МО "Городской округ город
                    Магас"
                  </td>
                </tr>
                <tr>
                  <td className="pad">2.</td>
                  <td>Султыгов Али Хаджибикарович</td>
                  <td>Начальник Штаба "Российского Союза ветеранов" по РИ</td>
                </tr>

                <tr>
                  <td className="pad">3.</td>
                  <td>Местоев Осман Муссаевич</td>
                  <td>
                    Главный специалист Управления внутренней политики
                    Администрации Главы и Правительства РИ
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div className="page__about-fond about-fond--row">
          <div className="fond--tasks about-fond--column">
            <div className="fond--tasks--title">
              Фонд создается в целях решения следующих задач:
            </div>
            <ul>
              <li>
                &mdash; На Аллее «100-летия Ингушетии», в г.Магасе, заложить и
                оборудовать информационными материалам «Сквер исторической
                памяти ингушского народа»;
              </li>
              <li>
                &mdash; Содействия в поиске материалов об историческом прошлом
                ингушского народа;
              </li>
              <li>
                &mdash; Для размещения всех материалов в едином центре и их
                демонстрации построить здание «Галереи памяти»
              </li>
              <li>
                &mdash; Установить памятник первому руководителю Ингушетии
                Идрису Бейсултановичу Зазикову.
              </li>
              <li>
                &mdash; Фонд ежегодно публикует отчёты о своей деятельности.
              </li>
            </ul>
          </div>
          <div className="fond--purpose about-fond--column">
            <div className="fond--tasks--title">
              Цели и предмет деятельности фонда
            </div>
            <p>
              &mdash; Основной целью Фонда является привлечение финансовых
              средств и формирование имущества от физических и юридических лиц
              на основе добровольных взносов и пожертвований, а также иных не
              запрещенных законом поступлений, с целью использования этих
              средств и этого имущества на деятельность по сооружению памятника
              первому руководителю Ингушетии Зязикову И.Б., сбору материалов об
              историческом прошлом ингушского народа и строительству
              историко-культурного - сооружения на Аллее «100-летия Ингушетии» в
              городе Магас, а также благоустройство данной территории.
            </p>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="list-of-donors">
          <div className="list-of-donors--title">
            Список жертвователей в фонд "ИНГИС"
          </div>
          <div className="list-of-donors-row">
            <div className="list-of-donors__individuals list-of-donors-column">
              <div className="list-of-donors__individuals--title">
                Россия (9 683 000тыс):
              </div>
              <table>
                <thead>
                  <tr>
                    <th>ФИО</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Калиматов Махмуд Али Макшарипович</td>
                    <td>300 тыс</td>
                    <td>Глава Республики Ингушетия</td>
                  </tr>
                  <tr>
                    <td>Костоев Исса Магометович</td>
                    <td>200 тыс</td>
                    <td>Учредитель фонда</td>
                  </tr>
                  <tr>
                    <td>Барахоев Бекхан Абдулхамидович</td>
                    <td>7 млн</td>
                    <td>Депутат Государственной думы</td>
                  </tr>
                  <tr>
                    <td>Паланкоев Ахмет Магомедович</td>
                    <td>1 млн</td>
                    <td>
                      Предприниматель, президент компании «Группа Акрополь»
                    </td>
                  </tr>
                  <tr>
                    <td>Хутиев Рамазан Магомедович</td>
                    <td>500 тыс</td>
                    <td>Генеральный Директор - ООО "КОНСАЛТ"</td>
                  </tr>
                  <tr>
                    <td>Харсиев Алихан Анатольевич</td>
                    <td>300 тыс</td>
                    <td>Депутатом Государственной думы</td>
                  </tr>
                  <tr>
                    <td>Батхиев А. К</td>
                    <td>150 тыс</td>
                    <td>Бывший член Верховного Суда РФ</td>
                  </tr>
                  <tr>
                    <td>Аушев Тагир Абдул-Хамидович</td>
                    <td>100 тыс</td>
                    <td>
                      Российский учёный в области физики элементарных частиц
                    </td>
                  </tr>
                  <tr>
                    <td>Амриева М. О</td>
                    <td>50 тыс</td>
                    <td>Директор фонда "ИНГИС"</td>
                  </tr>
                  <tr>
                    <td>Костоев Магомед Джабраилович</td>
                    <td>30 тыс</td>
                    <td>Сотрудник Министерства финанансов РИ</td>
                  </tr>
                  <tr>
                    <td>Джандигова Зарема Саварбековна</td>
                    <td>1 тыс</td>
                    <td>Жительница Малгобека</td>
                  </tr>
                  <tr>
                    <td>Келигова Любовь Султановна</td>
                    <td>2 тыс</td>
                    <td>Жительница Владикавказа</td>
                  </tr>
                  <tr>
                    <td>Точиева Светлана Яхьяевна</td>
                    <td>
                      50 тыс{" "}
                      <abbr title="Возврат внесенных в качестве добровольного пожертвования денежных средств">
                        (возврат)
                      </abbr>
                    </td>
                    <td>Пенсионерка</td>
                  </tr>
                  <tr>
                    <td>ООО "Мосавтодор"</td>
                    <td>---</td>
                    <td>---</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="list-of-donors__individuals list-of-donors-column">
              <div className="list-of-donors__individuals--title">
                Казахстан (600 тыс):
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Фамилия</th>
                    <th>Имя</th>
                    <th>Место жительства</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Ахриев</td>
                    <td>Илез</td>
                    <td>г. Алмата</td>
                  </tr>
                  <tr>
                    <td>Ахриев</td>
                    <td>Магомед</td>
                    <td>г. Алмата</td>
                  </tr>
                  <tr>
                    <td>Богатырев</td>
                    <td>Магомед</td>
                    <td>г. Алмата</td>
                  </tr>
                  <tr>
                    <td>Долгиев</td>
                    <td>Мурад</td>
                    <td>г. Алмата</td>
                  </tr>
                  <tr>
                    <td>Местоев</td>
                    <td>Р.А</td>
                    <td>г. Алмата</td>
                  </tr>
                  <tr>
                    <td>Местоев</td>
                    <td>Т.Р</td>
                    <td>г. Алмата</td>
                  </tr>
                  <tr>
                    <td>Местоев</td>
                    <td>Магомед</td>
                    <td>г. Алмата</td>
                  </tr>
                  <tr>
                    <td>Местоев</td>
                    <td>Ислам</td>
                    <td>г. Алмата</td>
                  </tr>
                  <tr>
                    <td>Местоев</td>
                    <td>Ваха</td>
                    <td>г. Алмата</td>
                  </tr>
                  <tr>
                    <td>Местоев</td>
                    <td>Заур</td>
                    <td>г. Алмата</td>
                  </tr>
                  <tr>
                    <td>Хашагульгов</td>
                    <td>Амирхан</td>
                    <td>г. Алмата</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="documents">
          <div className="documents--title-one">Документы</div>
          <div className="documents--title">
            <div class="doc_info">
              <div class="doc_name">
                <a href={Ustav}>
                  <h3>
                    Устав благотворительного фонда поддержки исторической памяти
                    ингушского народа "ИНГИС"
                  </h3>
                </a>
              </div>

              <div class="doc_open">
                <a href={Ustav}>
                  <span>открыть</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
